'use client'

import { MutableRefObject } from 'react'
import { clsx } from '../../utils/clsx'
import { getPortalType } from '../../utils/portal'
import { CaretLeft, CaretRight } from '../Icons'

export const PG_BTN_CLASS =
  'h-10 w-10 flex justify-center items-center rounded-full border border-gray-300 text-gray-400 bg-gray-100/50'
export const ACTIVE_PG_BTN_CLASS =
  'border-gray-400 text-gray-500 cursor-pointer !bg-white'

export const Arrows = ({
  swiperRef,
  className = '',
  canSlidePrev,
  canSlideNext,
}: {
  swiperRef: MutableRefObject<any>
  className: string
  canSlidePrev: boolean
  canSlideNext: boolean
}) => {
  const { isCFD } = getPortalType()
  return (
    <div
      className={clsx('transition-all ease-in-out duration-200', className)}
      style={{ userSelect: 'none' }}
    >
      <div
        id="image-swiper-button-next"
        onClick={() => swiperRef.current?.slidePrev()}
        className={clsx(
          PG_BTN_CLASS,
          canSlidePrev && ACTIVE_PG_BTN_CLASS,
          isCFD && 'ml-3 ',
        )}
      >
        <CaretLeft weight="bold" />
      </div>
      <div
        onClick={() => swiperRef.current?.slideNext()}
        className={clsx(
          PG_BTN_CLASS,
          'ml-3',
          canSlideNext && ACTIVE_PG_BTN_CLASS,
          isCFD && 'mr-3 ',
        )}
        id="image-swiper-button-prev"
      >
        <CaretRight weight="bold" />
      </div>
    </div>
  )
}
