'use client'

import '../styles/components/image-card-carousel.scss'

import { clsx } from "@licommon/utils/clsx";
import { getPortalType } from "@licommon/utils/portal";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useElementSize } from "usehooks-ts";
import { CaretLeft, CaretRight } from "./Icons";

const { isCALIBO, isCFD } = getPortalType()
const classPrefix = isCALIBO ? 'calibo_' : isCFD ? 'cfd_' : 'li_'

const PG_BTN_CLASS = `${classPrefix}button h-10 w-10 flex justify-center items-center rounded-full border border-gray-300 text-gray-400 z-10 absolute`
const ACTIVE_PG_BTN_CLASS = `${classPrefix}button_active border-gray-400 text-gray-500 cursor-pointer`

export class SliderConfig {
  count: (width: number) => number
  contentJustification: string

  constructor(
    count: (width: number) => number,
    justification: string = 'start',
  ) {
    this.count = count
    this.contentJustification = justification
  }
}

export default function ComponentSlider({
  title,
  totalCount,
  children,
  config = new SliderConfig((width) => {
    if (!width) return 5
    if (width < 400) return 2
    if (width < 800) return 3
    if (width < 1024) return 4
    return 4
  }),
  navClassName = '',
  prevButtonClass,
  nextButtonClass,
}: {
  title: string
  totalCount: number
  children: ReactNode
  config: SliderConfig
  navClassName?: string
  prevButtonClass?: string
  nextButtonClass?: string
}) {
  const [ref, { width }] = useElementSize()
  const inner = useRef<HTMLDivElement | null>(null)
  const scroller = useRef<HTMLDivElement | null>(null)

  const capacity = useMemo(() => {
    return config.count(width)
  }, [width, config])

  const [{ start, end }, setState] = useState(() => ({
    start: 0,
    end: capacity - 1,
  }))

  useEffect(() => {
    setState((s) => ({ ...s, end: capacity - 1 }))
  }, [capacity])

  const percent = `${(1 / capacity) * 100}%`

  function slide(delta: number) {
    const imageElement = document.querySelector('.image-card') as HTMLElement
    if (imageElement) {
      const imageWidth = imageElement.clientWidth
      let nearIndex = Math.round(scroller.current?.scrollLeft / imageWidth)
      if (delta > 0) {
        nearIndex += capacity
        if (nearIndex > totalCount - 1) {
          nearIndex = totalCount - 1
        }
      } else {
        nearIndex = nearIndex - 1
      }
      inner.current
        ?.querySelector(`[data-index="${nearIndex}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
          block: 'nearest',
        })

      return
    }

    if (delta === +1) {
      inner.current
        ?.querySelector(`[data-index="${end + 1}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
          block: 'nearest',
        })
    } else {
      inner.current
        ?.querySelector(`[data-index="${start - 1}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
          block: 'nearest',
        })
    }
    setState(() => ({
      start: start + delta,
      end: end + delta,
    }))
  }

  const [canSlideNext, setCanSlideNext] = useState(true);
  const [canSlidePrev, setCanSlidePrev] = useState(false);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
   const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
   const scrolled = scrollLeft + clientWidth;
   const isStart = scrollLeft === 0;
   const isEnd = scrolled >= (scrollWidth - 10)
   setCanSlidePrev(!isStart);
   setCanSlideNext(!isEnd);
 }

 useEffect(()=>{
   if(totalCount <= capacity){
      return setCanSlideNext(false)
   }
   setCanSlideNext(true)
 }, [capacity, totalCount])
 

  return (
    <div
      ref={ref}
      className={`${
        isCALIBO && 'calibo_image_card_carousel'
      } w-full relative ImageCardCarousel`}
      data-testid="ImageCardCarousel"
    >
      <div className="flex px-3 lg:px-0 justify-between items-center">
        {title && (
          <div
            className={clsx(
              isCALIBO && 'calibo_image_card_carousel__title',
              'font-medium text-blue-700 text-3xl flex-1 text-center',
            )}
          >
            {title}
          </div>
        )}
      </div>

      <div className="mb-4" />

      <div
        className={`${
          isCALIBO && 'calibo_image_card_carousel__cards uppercase-h3'
        } cards rounded-lg`}
        onScroll={onScroll}
        ref={scroller}
        style={{ overflow: 'hidden', userSelect: 'none', overflowX: 'auto' }}
      >
        <div
          ref={inner}
          style={{
            display: 'grid',
            gap: !isCALIBO ? '1rem' : '',
            gridAutoFlow: 'column',
            gridAutoColumns: `calc(${percent} - 1em)`,
            justifyContent:
              canSlideNext || canSlidePrev
                ? 'start'
                : config.contentJustification,
          }}
        >
          {children}
        </div>
      </div>

      <div
        className={clsx(
          'absolute hidden lg:w-[100px] xl:w-full h-auto xl:flex top-0 right-[10px] xl:right-0 xl:top-[49%] justify-between transition-all ease-in-out duration-200 z-200',
          navClassName,
        )}
      >
        {canSlidePrev && (
          <div
            onClick={() => slide(-1)}
            className={clsx(
              PG_BTN_CLASS,
              prevButtonClass || 'left-4 lg:-left-11',
              canSlidePrev && ACTIVE_PG_BTN_CLASS,
              'z-10'
            )}
            style={{ userSelect: 'none' }}
          >
            <CaretLeft weight="bold" />
          </div>
        )}
        {canSlideNext && (
          <div
            onClick={() => slide(+1)}
            className={clsx(
              PG_BTN_CLASS,
              nextButtonClass || 'right-12 lg:-right-11',
              canSlideNext && ACTIVE_PG_BTN_CLASS,
              'z-10'
            )}
            style={{ userSelect: 'none' }}
          >
            <CaretRight weight="bold" />
          </div>
        )}
      </div>
    </div>
  )
}
