'use client'

import '../../styles/components/image-card-carousel.scss'
import '../../styles/components/ranges/product-range-carousel.scss'
import { ComponentData, toTRangeCard } from '../../types'
import { getPortalType } from "../../utils/portal"
import ComponentSlider, { SliderConfig } from '../ComponentSlider'
import CaliboProductCard, { TRangeCard } from '../product-card/calibo-product-card/CaliboProductCard'

export default function ProductRangeCarousel({data} : {data: ComponentData}) {
    const { isCALIBO, isCFD } = getPortalType()
    const classPrefix = isCALIBO ? 'calibo_' : isCFD ? 'cfd_' : 'li_'

    const convertedRanges: TRangeCard[] = data && data.ranges ? toTRangeCard(data.ranges, 'ranges') : []

    if (!data) { return null } 
    
    return (
        <section className={`${classPrefix}product-ranges-carousel`}>
            <div className="top-content">
                {data.title !== '' && (<h2 className="title">{data.title ?? ''}</h2>)}
                {data.description !== '' && (<p dangerouslySetInnerHTML={{ __html: data.description || '' }} className="description"></p>)}
                {data.meta.buttons?.text !== '' && data.meta.buttons?.link !== '' && (<a className="link" href={data.meta.buttons?.link ?? '#'}>{data.meta.buttons?.text ?? ''}</a>)}
            </div>
            <ComponentSlider
            config={
            new SliderConfig((width) => {
                if (!width) return 4
                if (width < 400) return 2
                if (width < 1024) return 3
                return isCALIBO ? 3 : 4
            })
            }
            totalCount={data.ranges ? data.ranges.length : 0}
            title={''}
            >
            {convertedRanges.map((range, index) => {
                return (
                    <div data-index={index}
                    key={range.id}>
                    <CaliboProductCard key={range.id} isComparePage={false} isProduct={false} item={range} />
                    </div>
                )
            })}
            </ComponentSlider>
        </section>
    )
}