'use client'

import 'swiper/css/navigation'

import usePopupWishlist from '@licommon/hooks/whishlist/usePopupWishlist'
import useWishlist from '@licommon/hooks/whishlist/useWishlist'
import { useEffect, useRef, useState } from 'react'
import { Controller, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import { clsx } from '../../utils/clsx'
import { getPortalType } from '../../utils/portal'
import { Arrows } from './Arrows'
import CoverCard, { type Cover } from './CoverCard'
import ProductCard, { TProductCard } from './ProductCard'

type BreakPoint = {
  [width: number]: SwiperOptions
  [ratio: string]: SwiperOptions
}
export default function ProductCardCarousel({
                                              items,
                                              title,
                                              cover,
                                              showCover,
                                              variant = process.env.NEXT_PUBLIC_SITE_ID === '2' ? 'cfd' : 'li',
                                            }: {
  readonly items: TProductCard[]
  title: string
  readonly breakpoints?: BreakPoint
  variant?: 'li' | 'cfd'
  cover?: Cover
  showCover: boolean
}) {
  const swiperRef = useRef<any | null>(null)
  const [canSlidePrev, setCanSlidePrev] = useState(false)
  const [canSlideNext, setCanSlideNext] = useState(false)
  const { isCFD } = getPortalType()

  const { removeProductFromWishlist } = useWishlist()
  const { getProducts: getPopupProducts } = usePopupWishlist()

  const DEFAULT_BREAKPOINTS: BreakPoint = {
    372: { slidesPerView: variant === 'cfd' ? 2 : 2.02 },
    430: { slidesPerView: variant === 'cfd' ? 2.02 : 2.02 },
    480: { slidesPerView: variant === 'cfd' ? 2.04 : 2.02 },
    640: { slidesPerView: variant === 'cfd' ? 3.03 : 3 },
    890: { slidesPerView: variant === 'cfd' ? 3.02 : 3 },
    1024: { slidesPerView: variant === 'cfd' ? 4.01 : 4 },
    1336: { slidesPerView: variant === 'cfd' ? 5 : 5 },
  }

  useEffect(() => {
    updatePrevNext()
    window.addEventListener('resize', updatePrevNext)
    return () => window.removeEventListener('resize', updatePrevNext)
  }, [])

  const updatePrevNext = () => {
    setCanSlidePrev(swiperRef.current?.activeIndex > 0)
    const totalItems = items?.length + (showCover ? 1 : 0)
    setCanSlideNext(
        swiperRef.current?.activeIndex +
        swiperRef.current?.visibleSlides?.length <
        totalItems,
    )
  }

  const arrows = (
      <Arrows
          canSlidePrev={canSlidePrev}
          canSlideNext={canSlideNext}
          swiperRef={swiperRef}
          className={clsx(
              variant === 'cfd' && 'justify-between w-full ',
              'md:flex hidden',
          )}
      />
  )

  return (
      <div
          className="w-full p-0 product-card-carousel"
          data-testid="ProductCardCarousel"
      >
        <div
            className={clsx(
                !isCFD && 'mb-4',
                'px-3 md:px-0  flex justify-between items-center',
            )}
        >
          <div className="font-medium text-blue-700 text-3xl md:text-2xl flex-1 text-center md:text-left">
            {title}
          </div>
          {variant === 'li' && arrows}
        </div>

        {variant === 'cfd' && (
            <div
                className={`${
                    variant === 'cfd' &&
                    'relative translate-y-52 z-10 flex- justify-between'
                }`}
            >
              {arrows}
            </div>
        )}

        <Swiper
            onBeforeInit={(swiper) => (swiperRef.current = swiper)}
            spaceBetween={10}
            modules={[Navigation, Controller]}
            onSlideChange={() => setTimeout(() => updatePrevNext(), 0)}
            watchSlidesProgress
            breakpoints={DEFAULT_BREAKPOINTS}
        >
          {showCover && cover && (
              <SwiperSlide>
                {' '}
                <CoverCard cover={cover} />{' '}
              </SwiperSlide>
          )}
          {items?.map((item, i) => (
              <SwiperSlide key={i}>
                <ProductCard
                    product={item}
                    handleRemoveFromWishlist={async (productId) => {
                      await removeProductFromWishlist(productId)
                      await getPopupProducts()
                    }}
                    {...{ variant }}
                />
              </SwiperSlide>
          ))}
        </Swiper>

        <Arrows
            {...{ canSlideNext, canSlidePrev, swiperRef }}
            className="flex md:hidden mt-3 justify-between px-3"
        />
      </div>
  )
}
