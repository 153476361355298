'use client'

import { useAppSelector } from '@licommon/hooks/store'
import useIsWishlisted from '@licommon/hooks/whishlist/useIsWishlisted'
import { formatCurrency } from '@licommon/utils/currency'
import { truncate } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { ImageWithOverlay } from '../../types'
import { Label } from '../../types/Label'
import { clsx } from '../../utils/clsx'
import { getPortalType } from '../../utils/portal'
import Badge from '../Badge'
import Button from '../Button'
import { Star } from '../Icons'
import ImageOverlays from '../ImageOverlays'
import RatingStars from '../RatingStars'
import AuthFullModal from '../auth/AuthFullModal'
import AddToCompareButton from '../compares/AddToCompareButton'

export type TProductCard = {
  id: number
  price: number
  sale_price: number
  label: Label | null
  slug: string
  thumbnail: ImageWithOverlay
  name: string
  variations: number
  average_rating: number
  wishlisted: boolean
  supplierLogo?: string
}

export default function ProductCard(props: {
  className?: string
  product: TProductCard
  handleRemoveFromWishlist: (productId: number) => void
  variant?: 'li' | 'cfd'
  showCompareBtn?: boolean
  showWishList?: boolean
}) {
  const {
    className = '',
    product,
    variant = process.env.NEXT_PUBLIC_SITE_ID === '2' ? 'cfd' : 'li',
    showCompareBtn = true,
    showWishList = true,
  } = props
  const discount = parseInt(
    Math.round(product.price - product.sale_price).toFixed(2),
    10,
  )

  const [showAuthModal, setShowAuthModal] = useState<boolean>(false)
  const wishlisted = useIsWishlisted(product.id)
  const isCFD = variant === 'cfd'
  const { isCALIBO } = getPortalType()
  const canWishlist = useAppSelector((state) => state.auth.isLoggedIn)
  const router = useRouter()
  const toggleWishlist = (productId: number) => {
    if (!canWishlist) {
      setShowAuthModal(true)
      return
    }
    props.handleRemoveFromWishlist(productId)
  }
  return (
    <>
      <div
        className={`flex flex-col border-[2px] w-full h-full border-gray-300 overflow-hidden product-card relative ${
          variant === 'cfd' ? 'rounded-lg hover:bg-gray-50/50' : ''
        } ${className}`}
      >
        <div
          className="relative group cursor-pointer"
          onClick={() => {
            router.push(`/product/${product.slug}`)
          }}
        >
          {product.label && product.label.short_name ? (
            <div
              style={{
                background: product.label.color || '',
                color: product.label.text_color
                  ? product.label.text_color
                  : product.label?.color.length
                    ? 'white'
                    : '',
              }}
              className={clsx(
                'py-[4px] sm:py-1 text-[0.72rem] sm:text-base bg-blue-100 text-center font-medium absolute w-full z-2 block transition-opacity duration-200 ease-in opacity-100  group-hover:opacity-0 ',
                isCFD ? 'left-0 top-0' : 'top-0 left-0',
              )}
            >
              {product.label.short_name}
            </div>
          ) : null}
          <div
            className={`w-full  relative overflow-hidden flex justify-center h-auto cursor-pointer`}
          >
            <Image
              height={500}
              width={500}
              alt={product.name}
              className={clsx(
                'w-full h-full object-cover object-center transition-all ease-in-out duration-200 group-hover:scale-110',
                variant === 'cfd' && 'px-2 md:px-3',
              )}
              src={product.thumbnail.url}
              quality={90}
              // loading="eager"
            />
          </div>
          <ImageOverlays
            type="thumbnail"
            overlays={product.thumbnail.overlays}
          />
          {product.supplierLogo && (
            <div className="mx-2 md:mx-3 mt-2">
              <Image
                alt="Supplier Logo"
                height={40}
                width={80}
                className='object-contain object-left-top w-[80px] h-[40px]'
                src={product.supplierLogo}
              ></Image>
            </div>
          )}
        </div>
        <Link
          href={`/product/${product.slug}`}
          className={clsx(
            'p-1 lg:p-2 xl:p-3 flex flex-col flex-1 cursor-pointer',
            variant === 'cfd' && 'pt-2',
          )}
        >
          <div className="flex-1 justify-start">
            <Link
              href={`/product/${product.slug}`}
              className={`${
                !isCFD ? 'text-blue-700' : 'text-gray-900'
              } text-left text-sm line-clamp-3 sm:text-base leading-5 font-medium`}
            >
              {truncate(`${product.name}`, { length: 150 })}
            </Link>
            {!isCFD && (
              <div className="text-gray-500 text-left text-xs sm:text-base">
                {product.variations} Options
              </div>
            )}
          </div>
          <div className="my-2 min-h-[20px]">
            <RatingStars readOnly value={product.average_rating} />
          </div>
          {!isCFD && (
            <div className="flex gap-2 flex-col">
              <div className="flex items-baseline gap-1.5 ">
                {discount ? (
                  <div
                    className="font-semibold hidden sm:block text-gray-400 sm:text-base md:text-sm
                       line-through ml-1 text-base "
                  >
                    {formatCurrency(product.price)}
                  </div>
                ) : null}
                <div className="font-semibold text-red-medium text-xl sm:text-2xl md:text-xl">
                  {formatCurrency(product.sale_price)}
                </div>
              </div>
              <div className="flex flex-row gap-[3px] lg:gap-1 justify-start">
                {discount ? (
                  <Badge className="bg-blue-700">
                    {Math.round((discount / product.price) * 100)}% off
                  </Badge>
                ) : null}
                {discount ? (
                  <Badge className="bg-green-700">
                    Save {formatCurrency(discount, 0)}
                  </Badge>
                ) : null}
              </div>
            </div>
          )}
          {variant === 'cfd' && (
            <>
              <div className="flex gap-2 flex-col">
                <div className="flex gap-1 lg:gap-2 items-center justify-start">
                  {discount ? (
                    <Badge className="bg-red-700 rounded-lg">
                      {Math.round((discount / product.price) * 100)}% off
                    </Badge>
                  ) : null}
                  {discount ? (
                    <Badge className="bg-blue-700 rounded-lg">
                      Save {formatCurrency(discount, 0)}
                    </Badge>
                  ) : null}
                </div>

                <div
                  className={`flex items-center ${
                    isCFD ? 'gap-0' : 'justify-between'
                  }`}
                >
                  <div className="flex items-baseline gap-1 bg-yellow-400  transform -translate-x-3 md:-translate-x-5 p-1.5 rounded-lg w-fit px-2 md:px-3">
                    {isCFD && discount ? (
                      <div className="text-gray-600 hidden sm:block font-medium text-sm sm:text-xs line-through ml-1">
                        {formatCurrency(product.price)}
                      </div>
                    ) : null}
                    <div className="font-semibold text-gray-800 text-xl sm:text-md md:text-sm">
                      {formatCurrency(product.sale_price)}
                    </div>
                  </div>
                  {/* {canWishlist && showWishList ? (
                  <div
                    className={clsx(
                      'hover:text-yellow-500 sm:flex-none',
                      isCFD && 'absolute top-2 right-2  z-[11]',
                    )}
                  >
                    <Button
                      flat
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        toggleWishlist(product.id)
                      }}
                      className="border relative !px-1.5 !py-1.5 text-center flex justify-center group/star rounded-full bg-white opacity-80 border-gray-300"
                    >
                      <Star
                        size={23}
                        weight={wishlisted ? 'fill' : 'regular'}
                        className={clsx(
                          !wishlisted &&
                            'group-hover/star:text-yellow-500 transition-all duration-200 ease-in-out',
                          wishlisted && 'text-yellow-500',
                        )}
                      ></Star>
                    </Button>
                  </div>
                ) : null} */}
                </div>
              </div>
            </>
          )}
        </Link>

        {showCompareBtn && (
          <div className="border-t flex items-center border-gray-300 font-semibold text-gray-500">
            <AddToCompareButton
              showIcon={false}
              className={`border-gray-300 flex-1 flex justify-center 'w-full border-r`}
              addedBtnText="In Compare"
              addedBtnClassName="!text-[13px] h-full !px-[2px]"
              product={{
                id: product.id,
                name: product.name,
                slug: product.slug,
                img: product.thumbnail.url,
              }}
            ></AddToCompareButton>

            <div className="hover:text-yellow-500">
              <Button
                flat
                onClick={() => toggleWishlist(product.id)}
                className="w-full text-center flex gap-1 justify-center sm:justify-between group/star"
              >
                {/* <span className="hidden">Add to Wishlist</span> */}
                <Star
                  size={23}
                  weight={wishlisted ? 'fill' : 'regular'}
                  className={clsx(
                    !wishlisted &&
                      'group-hover/star:text-yellow-500 transition-all duration-200 ease-in-out',
                    wishlisted && 'text-yellow-500',
                  )}
                ></Star>
              </Button>
            </div>
          </div>
        )}
      </div>
      <AuthFullModal
        onClose={() => setShowAuthModal(false)}
        show={showAuthModal}
        title="Please Login or Register to Add to Wishlist"
        onSuccess={() => {
          setShowAuthModal(false)
        }}
      />
    </>
  )
}
