"use client";
import "../../styles/components/action-cards/action-cards.scss";
import { ComponentData } from "../../types";
import { getPortalType } from "../../utils/portal";

export default function ActionCards(props: { data: ComponentData }) {
  const { data } = props;

  const heading = data.title ?? "";
  const textDescription = data.subtitle ?? "";
  const cards = data.meta.images ?? [];
  const num = cards.length < 2 ? "one" : "two";
  const showTopSection = heading || textDescription ? true : false;
  const { isCALIBO, isCFD } = getPortalType();
  const classPrefix = isCALIBO ? "calibo_" : isCFD ? "cfd_" : "li_";
  const lengthThreshold = 100; //character length of tagline text description
  const isShortText = textDescription.length < lengthThreshold;

  return (
    <div className={`${classPrefix}action-cards`}>
      <div className={`action-cards_container ${num}`}>
        {showTopSection && (
          <div className="top-content">
            <h2>{heading}</h2>
            <p
              id="action-cards-tagline-text"
              className={isShortText ? "short-text" : ""}
            >
              {textDescription}
            </p>
          </div>
        )}
        <div className="card-deck">
          {cards?.map((card, i) => {
            if (i < 2) {
              return (
                <div className={"card"}>
                  {card.image.url && (
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${card.image.url})` }}
                    />
                  )}
                  <div className="inner-content">
                    <h3 className="uppercase-h3">{card.title ?? ""}</h3>
                    <span className="main-heading">
                      {card.description ?? ""}
                    </span>
                    <a href={card.link ?? "#"} className="btn">
                      {card.button.title ?? "Read More"}
                    </a>
                  </div>
                </div>
              );
            } else {
              return; //can't do more than 2 cards
            }
          })}
        </div>
      </div>
    </div>
  );
}
