import Image from 'next/image'
import Button from '../Button'

export type Cover = {
    title: string
    image: {
        url: string
        path: string
    }
    button_text: string
    button_link: string
}

interface CoverCardProps {
    cover: Cover
}

const CoverCard = ({ cover }: CoverCardProps) => {
    return (
        <div className="cover-card p-2 flex flex-col rounded-md justify-around w-full h-full bg-gray-50 overflow-hidden">
            <div>
                <h1 className="text-blue-brand font-semibold text-2xl">
                    {cover.title}
                </h1>
            </div>
            <div className='h-full mb-2'>
                <Image
                    className="rounded-md h-full object-cover"
                    src={cover.image.url}
                    alt={cover.image.path}
                    width={500}
                    height={500}
                    loading="eager"
                    priority={true}
                />
            </div>
            <div className="">
                <Button
                    href={cover.button_link}
                    variant="success"
                    flat
                    className={`border-none rounded-md flex justify-center items-center text-white`}
                >
                    {cover.button_text}
                </Button>
            </div>
        </div>
    )
}

export default CoverCard
