'use client'
import YouTube from 'react-youtube'
import '../../styles/components/sections/video-section.scss'
import { ComponentData } from '../../types'
import { getPortalType } from '../../utils/portal'

export default function VideoSection({
                                         data
                                     }: {
    data: ComponentData
}) {
    const { isCALIBO, isCFD } = getPortalType()
    const classPrefix = isCALIBO ? 'calibo_' : isCFD ? 'cfd_' : 'li_'
    const YTVideoID = data.meta.yt_link ? data.meta.yt_link.split('v=')[1] : ''
    const options = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    return (
        <section className={`${classPrefix}video-section`}>
            {(data.subtitle || data.title) && (
                <div className="video-title-content">
                    <div className="title-content">
                        <h3 className="uppercase-h3">{data.title ?? ''}</h3>
                        <p>{data.subtitle ?? ''}</p>
                    </div>
                    {data.meta.buttons?.link && data.meta.buttons?.text && (<a href={data.meta.buttons.link} className="btn">
                        {data.meta.buttons.text}
                    </a>)}
                </div>
            )}
            {data.meta.yt_link && (
                <YouTube
                    className={'video-player'}
                    videoId={YTVideoID}
                    opts={options}
                />
            )}
        </section>
    )
}
