export type CheckoutCustomerInformation = {
  customerId: number;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
};

export type CheckoutDeliveryInformation = {
  deliveryFirstName: string;
  deliveryLastName: string;
  deliveryAddress: string;
  deliverySuburb: string;
  deliveryState: string;
  deliveryZipCode: number;
  sameBillingAddress: boolean;
  billingAddress?: string;
  billingSuburb?: string;
  billingState?: string;
  billingZipCode?: number;
};

export type CheckoutPaymentInformation = {
  cardNumber: string; //format for like 0000-0000-0000-0000 is not a number so I chose string
  cardExpiry: number;
  cardCVC: number;
  cardHolderName: string;
};

import {
  ApplePayPayload,
  GooglePaymentTokenizePayload,
  HostedFieldsTokenizePayload,
  PayPalTokenizePayload,
} from "braintree-web";
import _ from "lodash";
import React from "react";

export const CARD_PAYMENT_METHOD_NO = 1;
export const PAYPAL_PAYMENT_METHOD_NO = 2;
export const AFTERPAY_PAYMENT_METHOD_NO = 3;
export const ZIPPAY_PAYMENT_METHOD_NO = 4;
export const GPAY_PAYMENT_METHOD_NO = 5;
export const APPLE_PAYMENT_METHOD_NO = 6;
export const BANK_TRANSFER_PAYMENT_METHOD_NO = 7;

export type NonceStateObject = {
  nonce:
    | GooglePaymentTokenizePayload
    | ApplePayPayload
    | HostedFieldsTokenizePayload
    | PayPalTokenizePayload;
  paymentMethod: number;
} | null;

export interface OrderResponse {
  success: boolean;
  orderId: number;
  amount: number;
  message: string;
  errors: string[];
}
export interface SiteSetting {
  amexSurCharge: number | null;
  zipPaySurCharge: number | null;
  afterPaySurCharge: number | null;
  enableZipPay: boolean;
  enableAfterPay: boolean;
}
export interface GuestRegisterError {
  new_password: string;
  password_confirmation: string;
}
