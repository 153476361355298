import { clsx } from '../utils/clsx'

type BadgeProps = {
  children: React.ReactNode
  className?: string
}
const Badge = ({ children, className = '' }: BadgeProps) => {
  return (
    <span
      className={clsx(
        'badge px-[0.5rem] py-[0.25rem] sm:p-1 lg:p-2 sm:py-1 font-semibold text-[0.68rem] md:text-[0.7rem] rounded-full text-white inline-block',
        className,
      )}
    >
      {children}
    </span>
  )
}
export default Badge
