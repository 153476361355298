'use client'
import { TProductCard } from "@licommon/components/product-card";
import { RootState, useAppSelector } from "@licommon/hooks/store";
import { setPagination, setProducts } from "@licommon/hooks/store/features/wishlistSlice";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paginated } from "../../types";
import useFetch from "../useFetch";
import usePost from "../usePost";

const useWishlist = () => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state: RootState) => state.wishlist);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const router = useRouter();
  const { loading: removing, post: removeProduct } = usePost(
    "api.customer.add-wishlist",
    {
      siteId: process.env.NEXT_PUBLIC_SITE_ID,
    }
  );

  const {
    loading,
    data,
    fetch: getProducts,
  } = useFetch<{ids:number[],products:Paginated<TProductCard[]>}>(
    "api.customer.get-wish-products",
    {
      page: wishlist.currentPage,
      per_page: wishlist.perPage,
      siteId: process.env.NEXT_PUBLIC_SITE_ID,
    },
    false
  );

  useEffect(() => {
    if (data?.products) {
      dispatch(setProducts(data.products.data));
      dispatch(
        setPagination({
          lastPage: data.products.last_page,
          total: data.products.total,
          perPage: data.products.per_page,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (isLoggedIn) getProducts();
  }, [wishlist.perPage, isLoggedIn]);

  async function removeProductFromWishlist(productId: number) {
    await removeProduct({
      productId: productId,
    });
  }

  return { loading, getProducts, removing, removeProductFromWishlist };
};

export default useWishlist;
